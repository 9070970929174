import React from 'react'

import styled from "styled-components"

const Wrapper = styled.article`
  text-align: center;
  font-family: "Montserrat";

  span {
    border: solid 4px #fff;
    font-size: 32px;
    letter-spacing: 8px;
    line-height: 64px;
    text-transform: uppercase;
    padding: 20px;
    padding-left: 28px;
  }
`

const PageHeading = ({ heading }) => (
  <Wrapper>
    <h1>
      <span>{heading}</span>
    </h1>
  </Wrapper>
)

export default PageHeading

import React from "react"
import { Link } from "gatsby"

// import twitter from "../images/social/01.svg"
// import facebook from "../images/social/03.svg"
// import linkedin from "../images/social/05.svg"
import instagram from "../images/social/instagram.svg"

const Footer = () => (
  <footer className="mastfoot container-fluid white-bg">
    <div className="row">
      <article className="credits col-md-6">
        <p className="font4 dark">
          Copyright &copy; {new Date().getFullYear()}{" "}
          <Link target="_blank" to="#">
            timecode.software
          </Link>
        </p>
      </article>
      <ul className="foot-social col-md-6 text-right">
{/*     <li>
          <Link to="#">
            <img alt="twitter" title="twitter" src={twitter} />
          </Link>
        </li>
        <li>
          <Link to="#">
            <img alt="facebook" title="facebook" src={facebook} />
          </Link>
        </li>
        <li>
          <Link to="#">
            <img alt="linkedin" title="linkedin" src={linkedin} />
          </Link>
        </li> */}
        <li>
          <Link to="https://www.instagram.com/helenridgewaymua/">
            <img alt="instagram" title="instagram" src={instagram} />
          </Link>
        </li>
      </ul>
    </div>
  </footer>
)

export default Footer
